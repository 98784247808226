import { useMemo, useSyncExternalStore } from 'react'

const useLocalStorage = <T>(key: string, serverValue?: T) => {
  const subscribe = (listener: () => void) => {
    window.addEventListener('storage', listener)
    return () => {
      window.removeEventListener('storage', listener)
    }
  }

  const setValue = (value: T) => localStorage.setItem(key, JSON.stringify(value))
  const getValue = () => {
    const item = localStorage.getItem(key)
    return item
  }

  const getSnapshot = (): string | null => getValue()
  const getServerSnapshot = (): string | null => JSON.stringify(serverValue) || null

  const value = useSyncExternalStore(subscribe, getSnapshot, getServerSnapshot)

  const parsedValue = useMemo(() => {
    return value ? (JSON.parse(value) as T) : null
  }, [value])

  return [parsedValue, setValue] as const
}

export default useLocalStorage