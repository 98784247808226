import { GatsbySSR } from 'gatsby'
import React from 'react'
import CookieWall from './src/components/marketing-website/CookieWall'
import { ConsentProvider } from './src/context/consent-context'

export const wrapPageElement: GatsbySSR['wrapPageElement'] = ({ element, props }) => {
  return (<ConsentProvider>
    <CookieWall />
    {element}
  </ConsentProvider>)
}
