export const wozCheckPrefix = '/woz-check/aanmelden'
export const huurprijsCheckPrefix = '/huurprijs-check'
export const mewozPrefix = '/mijn-eerlijke-woz'
export const aangifteCheckPrefix = '/aangifte-check'
export const requestsPrefix = '/verzoeken'

export const routes = {
  // WOZ Check
  address: `${wozCheckPrefix}/woning/adres/`,
  addressRegistration: `${wozCheckPrefix}/woning/adres-registratie/`,
  livingSituation: `${wozCheckPrefix}/woning/woonsituatie/`,
  recentlyBought: `${wozCheckPrefix}/woning/recent-gekocht/`,
  objectSubtype: `${wozCheckPrefix}/woning/woningtype/`,
  userArea: `${wozCheckPrefix}/woning/gebruiksoppervlakte/`,
  stateOfMaintenance: `${wozCheckPrefix}/woning/staat-van-onderhoud/`,
  ageOfBathRoomAndKitchen: `${wozCheckPrefix}/woning/leeftijd-badkamer-en-keuken/`,
  loadWoz: `${wozCheckPrefix}/woning/laden/`,
  wozKnown: `${wozCheckPrefix}/woning/woz-waarde/`,
  registerForObjection: `${wozCheckPrefix}/woning/registerForObjection`,
  transactionInPeriod: `${wozCheckPrefix}/woning/transactie-datum/`,
  transactionAmount: `${wozCheckPrefix}/woning/transactie-aankoopprijs/`,
  groundLease: `${wozCheckPrefix}/woning/erfpacht/`,
  groundLeasePerYear: `${wozCheckPrefix}/woning/erfpacht-per-jaar/`,
  rebuilt: `${wozCheckPrefix}/woning/renovatie/`,
  rebuiltBudget: `${wozCheckPrefix}/woning/verbouwingsbudget/`,
  rebuiltProgress: `${wozCheckPrefix}/woning/staat-van-verbouwing/`,
  newProperty: `${wozCheckPrefix}/woning/nieuwbouwwoning/`,
  calculatingEwoz: `${wozCheckPrefix}/uitslag/berekenen/`,
  recentToHigh: `${wozCheckPrefix}/uitslag/recent-teveel-belasting/`,
  notRecentToHigh: `${wozCheckPrefix}/uitslag/niet-recent-teveel-belasting/`,
  recentNotToHigh: `${wozCheckPrefix}/uitslag/recent-niet-te-hoog/`,
  notRecentNotToHigh: `${wozCheckPrefix}/uitslag/niet-recent-niet-te-hoog/`,
  orangeCase: `${wozCheckPrefix}/uitslag/niet-recent-teveel-belasting-op/`,
  // MEWOZ / General
  signOut: `${mewozPrefix}/uitloggen/`,
  signIn: `${mewozPrefix}/inloggen/`,
  register: `${mewozPrefix}/registreren/`,
  forgotPassword: `${mewozPrefix}/wachtwoord-vergeten/`,
  privacyPolicy: '/privacy/',
  termsAndConditions: '/algemene-voorwaarden/',

  requests: {
    payment: `${requestsPrefix}/vergoeding/`,
  },

  mewoz: {
    home: `${mewozPrefix}/`,
    faq: `${mewozPrefix}/veelgestelde-vragen`,
    settings: {
      overview: `${mewozPrefix}/account`,
      email: `${mewozPrefix}/account/email`,
      password: `${mewozPrefix}/account/wijzig-wachtwoord`,
      details: `${mewozPrefix}/account/contactgegevens`,
    },
    extraDetails: {
      overview: `${mewozPrefix}/woning`,
      type: `${mewozPrefix}/woning/algemeen`,
      surface: `${mewozPrefix}/woning/oppervlakte-ligging`,
      kitchen: `${mewozPrefix}/woning/keuken-badkamer`,
      maintenance: `${mewozPrefix}/woning/onderhoud`,
      durability: `${mewozPrefix}/woning/kwaliteit`,
      // extraSpace: `${mewozPrefix}/woning/overige-ruimtes/`,
      // sideBuildings: `${mewozPrefix}/woning/bijgebouwen/`,
      extraRemarks: `${mewozPrefix}/woning/opmerkingen`,
    },
    task: `${mewozPrefix}/bericht/:taskId/`,

    tasks: {
      taskCompleted: `${mewozPrefix}/bericht/:taskId/voltooid/`,
      extraDetails: `${mewozPrefix}/taak/bezwaar/kenmerken-woning/`,
      objectionOutcome: `${mewozPrefix}/taak/bezwaar/uitkomst/`,
      objectionSubmitted: `${mewozPrefix}/bericht/bezwaar/verzonden/`,
      poaMissing: `${mewozPrefix}/taak/bezwaar/machtiging-ontbreekt/`,
      poaTaskCompleted: `${mewozPrefix}/taak/bezwaar/machtiging-ontbreekt/voltooid`,
      reApply: `${mewozPrefix}/taak/woz-check/snelle-check/:activeYearPlusOne/`,
      valuationFinal: `${mewozPrefix}/taak/bezwaar/taxatie/`,
      wozCheckCompleted: `${mewozPrefix}/bericht/woz-check/afgerond/`,
      wozCheckNextYear: `${mewozPrefix}/bericht/woz-check/woz-notificatie-volgend-jaar/:activeYear/`,
      wozKnown: `${mewozPrefix}/taak/woz-check/woz-bekend/`,
      //phoneNumberMissing: TODO maybe this is preferred to add instead of default task URL
      //photo: TODO maybe this is preferred to add instead of default task URL
      //wozUnknown: TODO maybe this is preferred to add instead of default task URL
    },
  },

  // Aangifte Check
  upload: `${aangifteCheckPrefix}/upload/`,
  analysis: `${aangifteCheckPrefix}/uitslag/berekenen/`,
  intro: `${aangifteCheckPrefix}/`,
  result: `${aangifteCheckPrefix}/uitslag/`,
  thankYou: `${aangifteCheckPrefix}/bedankt/`,
  aangiftePrivacy: `${aangifteCheckPrefix}/privacy/`,
  aangifteGeneralConditions: `${aangifteCheckPrefix}/algemene-voorwaarden/`,
  // Renter flow
  rentLoadWoz: `${huurprijsCheckPrefix}/laden/`,
  rentLivingArea: `${huurprijsCheckPrefix}/gebruiksoppervlakte/`,
  rentPrivateFrontDoor: `${huurprijsCheckPrefix}/eigen-voordeur/`,
  rentRentPerMonth: `${huurprijsCheckPrefix}/prijs/`,
  rentLivingSince: `${huurprijsCheckPrefix}/looptijd-huurcontract/`,
  rentIncome: `${huurprijsCheckPrefix}/inkomen/`,
  rentCalculate: `${huurprijsCheckPrefix}/berekenen/`,
  rentCanSaveMoney: `${huurprijsCheckPrefix}/uitslag/besparen/`,
  rentCanNotSaveMoney: `${huurprijsCheckPrefix}/uitslag/niet-besparen/`,
} as const
