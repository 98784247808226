const body = typeof document !== "undefined" ? document.querySelector("body") : null
//const contentWrapper = typeof document !== 'undefined' ? document.getElementById('___gatsby') : null

let scrollTop: number | null = null

export const scrollLock = {
    enable() {
        if (body) {
            scrollTop = window.scrollY
            body.style.overflow = "hidden"
            body.style.position = "fixed"
            body.style.top = `-${scrollTop}px`
            body.style.width = "100%"
        }
    },
    disable() {
        if (body) {
            body.style.removeProperty("overflow")
            body.style.removeProperty("position")
            body.style.removeProperty("top")
            body.style.removeProperty("width")

            if (scrollTop !== null) {
                window.scrollTo(0, scrollTop)
                window.setTimeout(() => {
                    scrollTop = null
                }, 0)
            }
        }
    }

    // enable() {
    //     if (body && contentWrapper) {
    //         scrollTop = window.scrollY
    //         contentWrapper.style.top = `-${scrollTop}px`
    //         body.style.height = "100%"
    //         body.style.overflow = "hidden"
    //     }
    // },
    // disable() {
    //     if (body && contentWrapper) {
    //         contentWrapper.style.removeProperty("top")
    //         body.style.removeProperty("height")
    //         body.style.removeProperty("overflow")
    //
    //         if (scrollTop) {
    //             window.scrollTo(0, scrollTop)
    //         }
    //
    //         window.setTimeout(() => {
    //             scrollTop = null
    //         }, 0)
    //     }
    // }
}
