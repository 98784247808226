import type { GatsbyBrowser } from 'gatsby'
import * as React from 'react'
import { createStore } from 'little-state-machine'
import type { GatsbySSR } from 'gatsby'

createStore(
  {},
  {
    name: 'woz_check',
  }
)

export * from './gatsby-wrap-root-element'
export * from './gatsby-wrap-page-element'

export const onRouteUpdate: GatsbyBrowser['onRouteUpdate'] = ({ prevLocation }) => {
  if (prevLocation) {
    // @ts-ignore
    window.prevLocation = prevLocation
  }
}
