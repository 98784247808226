import styled from '@emotion/styled';

import * as SwitchPrimitive from '@radix-ui/react-switch';

export const SwitchRoot = styled(SwitchPrimitive.Root)(({ theme }) => ({
  all: 'unset',
  width: 42,
  height: 25,
  backgroundColor: theme.colors.N30,
  borderRadius: 9999,
  position: 'relative',
  cursor: 'pointer',
  '&[data-state="checked"]': {
    backgroundColor: theme.colors.G300
  },
  '&[data-disabled=""]': {
    cursor: 'not-allowed',
    backgroundColor: '#24c27f80'
  }
}))

export const SwitchThumb = styled(SwitchPrimitive.Thumb)(() => ({
  display: 'block',
  width: 20,
  height: 20,
  backgroundColor: 'white',
  borderRadius: 9999,
  transition: 'transform 100ms',
  transform: 'translateX(2px)',
  willChange: 'transform',
  '&[data-state="checked"]': {
    transform: 'translateX(19px)'
  }
}))