module.exports = [{
      plugin: require('../.yarn/__virtual__/gatsby-plugin-manifest-virtual-6fafc57397/0/cache/gatsby-plugin-manifest-npm-5.13.1-2036e9f84e-517b0b8965.zip/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"src/images/favicon.svg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"e7bf6d30fbc6f4e284f71dfde618eec5"},
    },{
      plugin: require('../.yarn/__virtual__/gatsby-plugin-gatsby-cloud-virtual-e8f1e2a77d/0/cache/gatsby-plugin-gatsby-cloud-npm-5.13.1-3d3464d8f5-98946aafa0.zip/node_modules/gatsby-plugin-gatsby-cloud/gatsby-browser.js'),
      options: {"plugins":[],"headers":{"/storybook/*":["Basic-Auth: webbersagency:webbersagency","X-Frame-Options: SAMEORIGIN"]},"allPageHeaders":["Cache-Control \"public, max-age=0, must-revalidate\"","Strict-Transport-Security: max-age=31536000; includeSubDomains; preload"]},
    },{
      plugin: require('../.yarn/cache/gatsby-plugin-rollbar-npm-1.1.0-b8647dc0c5-eaa873c137.zip/node_modules/gatsby-plugin-rollbar/gatsby-browser.js'),
      options: {"plugins":[],"accessToken":"a4191ca032084fe8af2a1ef8a2ba80fb","captureUncaught":false,"captureUnhandledRejections":false,"payload":{"environment":"production"}},
    },{
      plugin: require('../.yarn/__virtual__/gatsby-plugin-google-tagmanager-virtual-5799030082/0/cache/gatsby-plugin-google-tagmanager-npm-5.13.1-f82e0c3055-f375ab868f.zip/node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-WPXSKLH","includeInDevelopment":true,"defaultDataLayer":{"type":"function","value":"()=>({originalLocation:document.location.protocol+\"//\"+document.location.hostname+document.location.pathname+document.location.search})"},"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com","selfHostedPath":"gtm.js"},
    },{
      plugin: require('../.yarn/cache/gatsby-plugin-facebook-pixel-npm-1.0.8-5aef36b44a-f627bf8f64.zip/node_modules/gatsby-plugin-facebook-pixel/gatsby-browser.js'),
      options: {"plugins":[],"pixelId":"1645000242468792"},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../.yarn/unplugged/gatsby-virtual-d273eb7c5a/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
