import Button from '@components/shared/Button'
import { ModalContainer } from '@components/shared/ModalV2/styles'
import Switch from '@components/shared/Switch'
import { ConsentContextState, useConsent } from '@context/consent-context'
import ArrowLeft from '@icons/ArrowLeft'
import EwozHeaderLogo from '@icons/EwozHeaderLogo'
import LockIcon from '@icons/LockIcon'
import pushToDataLayer from '@logic/data-layers/pushToDataLayer'
import { scrollLock } from '@utils/scrollLock'
import React, { FC, useEffect, useRef, useState } from 'react'
import ReactDOM from 'react-dom'
import { WallContentWrapper, WallFooter, WallModalCard, WallModalWrapper, WallSettings, WallTopRow } from './styles'

type ConsentTypes = {
  [key: string]: {
    title: string
    description: string
  }
}

const consentTypes: ConsentTypes = {
  functional: {
    title: 'Functionele cookies',
    description: 'Deze cookies zijn noodzakelijk voor de basisfunctionaliteit van onze website en kunnen niet worden uitgeschakeld. Ze ondersteunen functies zoals pagina-navigatie en toegang tot beveiligde delen van de website.'
  },
  analytical: {
    title: 'Analytische cookies',
    description: 'Deze cookies helpen ons te begrijpen hoe bezoekers onze website gebruiken, door informatie te verzamelen en te rapporteren. Dit helpt ons de website te verbeteren.'
  },
  marketing: {
    title: 'Marketing cookies',
    description: 'Deze cookies worden gebruikt om advertenties te tonen die relevanter zijn voor jou en je interesses. Ze worden ook gebruikt om de effectiviteit van advertentiecampagnes te meten.'
  }
}

const CookieWall: FC = () => {
  const modalRoot = useRef<HTMLDivElement | null>(null)
  const [consentInput, setConsentInput] = useState<ConsentContextState>({ functional: true, analytical: true, marketing: true, allAccepted: false, allDenied: false })
  const [showSettings, setShowSettings] = useState(false)
  const { open, consent, settingsOnly, submitConsent, closeCookieWall } = useConsent()

  const handleConsentChange = (type: keyof ConsentContextState, value: boolean) => {
    setConsentInput(prev => ({ ...prev, [type]: value, allAccepted: false, allDenied: false }))
  }

  const handleAcceptAll = () => {
    pushToDataLayer({
      event: 'interaction',
      event_name: `cookie_consent${showSettings ? '_settings' : ''}_accepted`,
    })
    const consent = { functional: true, analytical: true, marketing: true, allAccepted: true, allDenied: false }
    setConsentInput(consent)
    submitConsent(consent)
  }

  const handleDenyAll = () => {
    pushToDataLayer({
      event: 'interaction',
      event_name: 'cookie_consent_settings_denied',
    })
    const consent = { functional: true, analytical: false, marketing: false, allAccepted: false, allDenied: true }
    setConsentInput(consent)
    submitConsent(consent)
  }

  const handleSubmitConsent = () => {
    pushToDataLayer({
      event: 'interaction',
      event_name: 'cookie_consent_settings_saved',
    })
    submitConsent(consentInput)
  }

  useEffect(() => {
    scrollLock[open ? 'enable' : 'disable']()
    return () => scrollLock.disable()
  }, [open])

  useEffect(() => {
    if(settingsOnly) pushToDataLayer({
      event: 'interaction',
      event_name: 'cookie_consent_settings_open',
    })
    setShowSettings(settingsOnly)
  }, [settingsOnly])

  useEffect(() => {
    setConsentInput({ ...consentInput, ...consent })
  }, [consent])

  useEffect(() => {
    const element = document.querySelector<HTMLDivElement>('#modal-root')
    modalRoot.current = element
  
    return () => {
      modalRoot.current = null
    }
  }, [])

  if (!modalRoot.current || !open) return null

  return ReactDOM.createPortal(
    <ModalContainer>
      <WallModalWrapper>
        <WallModalCard onClick={e => e.stopPropagation()}>
          <WallTopRow>
            {showSettings && (
              <Button iconLeft={<ArrowLeft />} variant="outlinedPrimary" onClick={() => {
                setShowSettings(false)
                if(settingsOnly) closeCookieWall()
                else pushToDataLayer({
                  event: 'interaction',
                  event_name: 'cookie_consent_settings_open',
                })
              }}>
                Terug
              </Button>
            )}
            {EwozHeaderLogo()}
          </WallTopRow>
          <WallContentWrapper>
            {showSettings ? (
              <>
                <h2>Jouw privacy-instellingen</h2>
                <p>Op deze pagina kun je jouw voorkeuren instellen voor het gebruik van cookies en andere trackingtechnologieën. Hieronder vind je informatie over de verschillende typen cookies die we gebruiken en wat ze doen. Je kunt per categorie beslissen of je ze wilt toestaan.</p>
                <WallSettings>
                  {Object.entries(consentTypes).map(([type, { title, description }]) => (
                    <div key={type}>
                      <h4>{title}</h4>
                      <p>{description}</p>
                      <div>
                        {type === 'functional' && <LockIcon />}
                        <Switch
                          checked={consentInput[type as keyof ConsentContextState]}
                          disabled={type === 'functional'}
                          onCheckedChange={(value:boolean) => handleConsentChange(type as keyof ConsentContextState, value)}
                        />
                      </div>
                    </div>
                  ))}
                </WallSettings>
                <WallFooter settings>
                  <Button variant='secondary' onClick={handleAcceptAll}>Alles accepteren</Button>
                  <Button variant='secondary' onClick={handleDenyAll}>Alles weigeren</Button>
                  <Button onClick={handleSubmitConsent}>Voorkeuren opslaan</Button>
                </WallFooter>
              </>
            ) : (
              <>
                <h2>Jouw privacy-instellingen</h2>
                <p>Bij Eerlijke WOZ hechten we veel waarde aan jouw privacy. Op onze website gebruiken wij en specifieke partners cookies en andere technologieën om informatie te verzamelen over jouw apparaat en jouw activiteiten op onze site. Deze informatie wordt gebruikt om de website te optimaliseren en jouw ervaring te personaliseren.</p>
                <p>We zullen nooit locatiegegevens gebruiken voor het selecteren van advertenties zonder jouw expliciete toestemming.</p><b>Geef toestemming of stel je eigen keuze in</b>
                <p>Je kunt hieronder toestemming geven voor het plaatsen van deze cookies. Je kunt je toestemming op elk moment aanpassen via de privacy instellingen onderaan onze website.</p> 
                <WallFooter>
                  <Button onClick={handleAcceptAll}>Akkoord</Button>
                  <Button variant='secondary' onClick={() => setShowSettings(true)}>Instellingen</Button>
                </WallFooter>
              </>
            )}
          </WallContentWrapper>
        </WallModalCard>
      </WallModalWrapper>
    </ModalContainer>,
    modalRoot.current
  )
}

export default CookieWall