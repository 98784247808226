import * as React from 'react'
import type { GatsbySSR } from 'gatsby'
import * as ToastPrimitive from '@radix-ui/react-toast'
import { ThemeProvider } from '@emotion/react'
import { theme } from './src/theme'
import { StateMachineProvider } from 'little-state-machine'
import { isBrowser } from './src/utils/isBrowser'

const setCookie = (cname, cvalue) => {
  const d = new Date()
  d.setTime(d.getTime() + 365 * 24 * 60 * 60 * 1000)
  const expires = 'expires=' + d.toUTCString()
  isBrowser
    ? (document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/;domain=.eerlijkewoz.nl;secure;samesite=none')
    : null
}

const hasCookie = name => {
  if (isBrowser) {
    const value = `; ${document.cookie}`
    const parts = value.split(`; ${name}=`)
    return parts.length === 2
  }
}

export const wrapRootElement: GatsbySSR['wrapRootElement'] = ({ element }) => {
  if (!hasCookie('first_session')) {
    setCookie('first_session', 'true')
    setTimeout(() => {
      setCookie('first_session', 'false')
    }, 60000)
  } else {
    setCookie('first_session', 'false')
  }

  return (
    <ToastPrimitive.Provider swipeDirection="left">
      <ThemeProvider theme={theme}>
        <StateMachineProvider>{element}</StateMachineProvider>
      </ThemeProvider>
    </ToastPrimitive.Provider>
  )
}
