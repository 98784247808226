import styled from '@emotion/styled';

export const ModalContainer = styled.div(({ theme }) => ({
  zIndex: 1060,
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backdropFilter: 'blur(10px)',
  overflowX: 'hidden',
  overflowY: 'auto',
  cursor: 'pointer',
}))

export const ModalWrapper = styled('div')<{ small?: boolean }>(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  minHeight: 'calc(100% - (.5rem * 2))',
  position: 'relative',
  width: 'auto',
  margin: 8,
  padding: '16px 0',
  pointerEvents: 'none',
}))

export const ModalCard = styled('div', {
  shouldForwardProp: prop => prop !== 'small' && prop !== 'noMarginOnP',
})<{ small?: boolean; noMarginOnP?: boolean }>(({ theme, small, noMarginOnP }) => ({
  pointerEvents: 'auto',
  background: '#fff',
  borderRadius: 16,
  overflow: 'auto',
  width: small ? 560 : 800,
  maxWidth: 'calc(100% - 16px)',
  boxShadow:
    '0px 0px 0px 1px rgba(0, 0, 0, 0.05), 0px 8px 12px 1px rgba(0, 0, 0, 0.05), 0px 1px 2px rgba(0, 0, 0, 0.15)',
  '& p': {
    marginBottom: noMarginOnP ? 0 : 24,
  },
}))

export const ContentWrapper = styled.div(({ theme }) => ({
  padding: 24,
  overflow: 'hidden',
  '& h2': {
    marginBottom: 8,
  },
}))

export const SubmittedWrapper = styled.div(({ theme }) => ({
  padding: 24,
  display: 'flex',
  flexDirection: 'column',
  gap: 16,
  '& h2': {
    display: 'flex',
    alignItems: 'center',
    gap: 24,
  },
  '& p': {
    margin: 0,
  },
}))

export const TopRow = styled.div(({ theme }) => ({
  display: 'flex',
  padding: '15px 24px 11px',
  justifyContent: 'space-between',
  alignItems: 'center',
  borderBottom: '2px solid rgba(0, 0, 0, 0.1)',
  minHeight: 72,
  '& div': {
    cursor: 'pointer',
  },
}))

export const ButtonWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  marginTop: 16,
  '& button, & a': {
    '& span': {
      fontSize: 14,
      lineHeight: 1.143,
    },
    '&:not(:first-child)': {
      marginLeft: 16,
      [theme.breakpoint.down('md')]: {
        marginLeft: 0,
        marginTop: 8,
      },
    },
    [theme.breakpoint.down('md')]: {
      width: '100%',
    },
  },
  [theme.breakpoint.down('md')]: {
    flexDirection: 'column',
  },
}))

export const BottomRow = styled('div')(({ theme }) => ({
  marginTop: 24,
  minHeight: 72,
  flexWrap: 'wrap',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: 16,
  borderTop: '2px solid rgba(0, 0, 0, 0.1)',
  padding: 24,
  '& span': {
    lineHeight: 24 / 16,
    color: theme.colors.N500,
  },
}))

export const InfoWrapper = styled('div')(({ theme }) => ({
  '& p': {
    margin: 0,
  },
}))
