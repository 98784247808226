import useLocalStorage from '@hooks/useLocalStorage'
import pushToDataLayer from '@logic/data-layers/pushToDataLayer'
import React, { ReactNode, createContext, useContext, useEffect, useState } from 'react'

export type ConsentContextState = {
  functional: boolean
  analytical: boolean
  marketing: boolean
  allAccepted: boolean
  allDenied: boolean
}

export type ConsentContextProps = {
  open: boolean
  consent: ConsentContextState | null
  settingsOnly: boolean,
  submitConsent: (state: ConsentContextState) => void
  openCookieWall: (settings?: boolean) => void
  closeCookieWall: () => void
}

type ConsentProviderProps = {
  children: ReactNode
}

const ConsentContext = createContext<ConsentContextProps>({
  open: false,
  consent: null,
  settingsOnly: false,
  submitConsent: () => undefined,
  openCookieWall: () => undefined,
  closeCookieWall: () => undefined,
})

export const useConsent = () => useContext(ConsentContext)

export const ConsentProvider = ({ children }: ConsentProviderProps) => {
  const [open, setOpen] = useState(false)
  const [settingsOnly, setSettingsOnly] = useState(false)
  const [consent, setConsent] = useLocalStorage('consent', { allDenied: true })

  const updatePlugins = () => {
    const allowMarketing = consent?.marketing || consent?.allAccepted
    const allowAnalytical = consent?.analytical || consent?.allAccepted

    function gtag() {
      window.dataLayer = window.dataLayer || []
      window.dataLayer.push(arguments)
    }

    gtag('consent', 'default', {
      'ad_storage': allowMarketing ? 'granted' : 'denied',
      'ad_user_data': allowMarketing ? 'granted' : 'denied',
      'ad_personalization': allowMarketing ? 'granted' : 'denied',
      'analytics_storage': allowAnalytical ? 'granted' : 'denied',
    })

    if(allowMarketing) {
      if(process.env.GATSBY_HOTJAR_ID && !window.hj) {
        (function(h,o,t,j,a,r){
          h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
          h._hjSettings={hjid:process.env.GATSBY_HOTJAR_ID,hjsv:6};
          a=o.getElementsByTagName('head')[0];
          r=o.createElement('script');r.async=1;
          r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
          a.appendChild(r);
        })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=')
      }

      if(process.env.GATSBY_FB_PIXEL_ID && window.fbq)
        window.fbq('consent', 'grant')

    } else {
      if(window.fbq) window.fbq('consent', 'revoke')
      if(window.hj) window.location.reload()
    }
  }

  const openCookieWall = (settings?: boolean) => {
    setSettingsOnly(!!settings)
    if(!settings) pushToDataLayer({
      event: 'notification',
      event_name: 'cookie_consent_open',
    })
    setOpen(true)
  }
  const closeCookieWall = () => {
    setSettingsOnly(false)
    setOpen(false)
  }

  const submitConsent = (state: ConsentContextState) => {
    setConsent(state)
    closeCookieWall()
  }

  useEffect(() => {
    if (consent)
      updatePlugins()
    else {
      updatePlugins()
      openCookieWall()
    }
  }, [consent])

  return (
    <ConsentContext.Provider value={{ open, settingsOnly, openCookieWall, closeCookieWall, consent, submitConsent }}>
      {children}
    </ConsentContext.Provider>
  )
}