import styled from "@emotion/styled"
import {ButtonProps} from "@components/shared/Button/types"
import {Theme} from "@emotion/react"

export const PlainButton = styled("button", {
    shouldForwardProp: prop => prop !== "small",
})<Partial<ButtonProps>>(({theme, small}) => ({
    ...getButtonStyles(theme, small),
}))

export const getButtonStyles = (theme: Theme, small?: boolean): object => ({
    appearance: "none",
    cursor: "pointer",
    position: "relative",
    color: theme.colors.N0,
    boxShadow:
        "0px 0px 0px 1px rgba(0, 0, 0, 0.1), 0px 1px 4px rgba(0, 0, 0, 0.1)",
    width: "fit-content",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 6,
    border: "none",
    boxSizing: "border-box",
    "& *": {
        pointerEvents: "none",
    },
    "& svg": {
        transition: "all 400ms ease",
    },
    "& span": {
        fontFamily: theme.fonts.body,
        fontWeight: theme.fontWeights.bold,
        fontSize: theme.fonts.pxToRem(small ? 14 : 18),
        padding: "0 !important",
        whiteSpace: "nowrap",
    },
    height: small ? 40 : 56,
    padding: small ? "12px 16px" : "16px 20px",
    [theme.breakpoint.down("md")]: {
        height: small ? 40 : 48,
        padding: "12px 16px",
        "& span": {
            fontSize: theme.fonts.pxToRem(small ? 12 : 14),
        },
    },
    "&.mobile": {
        height: small ? 40 : 48,
        padding: "12px 16px",
        "& span": {
            fontSize: theme.fonts.pxToRem(small ? 12 : 14),
        },
    },
    "&.primary": {
        background: theme.colors.B300,
        transition: "all 400ms ease",
        "&:not([disabled]):hover, .hover": {
            background: "#0061FF", //TODO ask designer to change to one of the colors in the schema
        },
        "&[disabled]": {
            background: `${theme.colors.N50} !important`,
            cursor: "not-allowed",
            "& span": {
                color: theme.colors.N400,
            },
        },
        "&.active": {
            background: `${theme.colors.N600} !important`,
        },
        "&.hover": {
            background: theme.colors.B300,
        },
        [theme.breakpoint.down("md")]: {
            "& span": {
                fontSize: theme.fonts.pxToRem(small ? 14 : 18),
                lineHeight: small ? 1.428 : 1.333,
            },
        },
    },
    "&.roundedPrimary": {
        background: theme.colors.B300,
        borderRadius: 80,
        transition: "all 400ms ease",
        "&:not([disabled]):hover, .hover": {
            background: "#0061FF", //TODO ask designer to change to one of the colors in the schema
        },
        "&[disabled]": {
            background: `${theme.colors.N50} !important`,
            cursor: "not-allowed",
            "& span": {
                color: theme.colors.N400,
            },
        },
        "&.active": {
            background: `${theme.colors.N600} !important`,
        },
        "&.hover": {
            background: theme.colors.B300,
        },
    },
    "&.roundedSecondary": {
        background: theme.colors.N30,
        borderRadius: 80,
        color: theme.colors.N600,
        boxShadow: "none",
        transition: "all 400ms ease",
        "&:not([disabled]):hover, .hover": {
            background: theme.colors.N50,
        },
        "&[disabled]": {
            background: `${theme.colors.N50} !important`,
            cursor: "not-allowed",
            "& span": {
                color: theme.colors.N400,
            },
        },
        "&.active": {
            background: `${theme.colors.N600} !important`,
        },
        "&.hover": {
            background: theme.colors.N50,
        },
    },
    "&.roundedTertiary": {
        background: theme.colors.N0,
        borderRadius: 80,
        color: theme.colors.B300,
        boxShadow: "none",
        transition: "all 400ms ease",
        "&:not([disabled]):hover, .hover": {
            background: theme.colors.N30,
        },
        "&[disabled]": {
            background: `${theme.colors.N50} !important`,
            cursor: "not-allowed",
            "& span": {
                color: theme.colors.N400,
            },
        },
        "&.active": {
            background: `${theme.colors.N600} !important`,
        },
        "&.hover": {
            background: theme.colors.N50,
        },
    },
    "&.outlinedPrimary": {
        background: theme.colors.N0,
        color: theme.colors.N500,
        padding: "8px 8px 8px 11px !important",
        display: "flex",
        alignItems: "center",
        height: 36,
        [theme.breakpoint.down("md")]: {
            height: 32,
        },
        "& span": {
            fontSize: 14,
            fontWeight: theme.fontWeights.body,
            [theme.breakpoint.down("md")]: {
                fontSize: 12,
            },
        },
    },
    "&.secondary": {
        background: theme.colors.N0,
        transition: "all 400ms ease",
        "& span": {
            color: theme.colors.N600,
        },
        "&:not([disabled]):hover": {
            background: theme.colors.N20,
        },
        "&.hover": {
            background: theme.colors.N20,
        },
        "&[disabled]": {
            background: `${theme.colors.N50} !important`,
            cursor: "not-allowed",
            "& span": {
                color: theme.colors.N400,
            },
        },
        "&.active": {
            background: `${theme.colors.N60} !important`,
        },
    },
    "&.aangiftePrimary": {
        background: theme.colors.A500,
        transition: "all 400ms ease",
        "&:not([disabled]):hover, .hover": {
            background: theme.colors.A700,
        },
        "&[disabled]": {
            background: `${theme.colors.N50} !important`,
            cursor: "not-allowed",
            "& span": {
                color: theme.colors.N400,
            },
        },
        "&.active": {
            background: `${theme.colors.N600} !important`,
        },
        "&.hover": {
            background: theme.colors.B300,
        },
        [theme.breakpoint.down("md")]: {
            "& span": {
                fontSize: theme.fonts.pxToRem(small ? 14 : 18),
                lineHeight: small ? 1.428 : 1.333,
            },
        },
    },
    "&.aangiftePrimaryRounded": {
        marginRight: 30,
        borderRadius: 80,
        width: 106,
        height: 32,
        background: theme.colors.A500,
        transition: "all 400ms ease",
        "&:not([disabled]):hover, .hover": {
            background: theme.colors.A700,
        },
        "&.hover": {
            background: theme.colors.B300,
        },
        "& span": {
            fontSize: theme.fonts.pxToRem(14),
            lineHeight: 16 / 14,
        },
    },
    "&.readMore": {
        background: "rgba(0, 0, 0, 0.4)",
        transition: "all 400ms ease",
        "&:not([disabled]):hover, .hover": {
            background: "rgba(0, 0, 0, 0.6)",
        },
        [theme.breakpoint.down("md")]: {
            "& span": {
                fontSize: theme.fonts.pxToRem(small ? 14 : 18),
                lineHeight: small ? 1.428 : 1.333,
            },
        },
    },
})

export const ImageContainer = styled.div(({theme}) => ({
    display: "flex",
    flexShrink: 0,
    alignItems: "center",
    "& svg": {
        // marginLeft: 20,
        marginRight: -6,
        marginTop: -9,
        marginBottom: -9,
    },
    "&.icon-right": {
        "& svg": {
            marginLeft: 20,
        },
    },
    "&.icon-left": {
        "& svg": {
            marginRight: 13,
        },
    },
    "&.icon-loading": {
        "& svg": {
            marginLeft: 10,
            width: 24,
            height: 24,
        },
    },
}))

export const IconButton = styled.button(({theme}) => ({}))
