import * as React from "react"
import {IconButton, ImageContainer, PlainButton} from "./styles"
import {ButtonProps} from "./types"
import {Link} from "gatsby"
import LoadingIcon from "@icons/LoadingIcon"
import {routes} from "@routes/index"

const Button: React.FC<ButtonProps> = ({
    children,
    type = "button",
    icon,
    variant = "primary",
    forceHover = false,
    forceMobile = false,
    active = false,
    disabled = false,
    small = false,
    url: passedUrl,
    isInternal,
    iconRight,
    iconLeft,
    isLoading,
    download,
    discountPrice = null,
    linkType,
    ...props
}) => {
    const url = passedUrl || props.href
    const noIcon = !iconRight && !iconLeft ? "noIcon" : ""
    const buttonProps = {
        type,
        ...props,
        small: small,
        className: `${variant} ${noIcon} ${forceHover ? "hover" : ""} ${
            active ? "active" : ""
        }  ${forceMobile ? "mobile" : ""}`,
        disabled: isLoading || disabled,
    }

    const buttonContent = (
        <>
            {iconLeft && (
                <ImageContainer className="icon-left">
                    {iconLeft}
                </ImageContainer>
            )}
            <span>{children}</span>
            {iconRight && (
                <ImageContainer className="icon-right">
                    {iconRight}
                </ImageContainer>
            )}
            {isLoading && (
                <ImageContainer className="icon-loading">
                    <LoadingIcon />
                </ImageContainer>
            )}
        </>
    )
    if (linkType === "wozCheckCta") {
        const InternalButton = PlainButton.withComponent(Link)
        return (
            <InternalButton to={routes.address} {...buttonProps}>
                {buttonContent}
            </InternalButton>
        )
    }
    if (url) {
        const {type, ...linkButtonProps} = buttonProps
        let isExternal = false

        if (
            url?.startsWith("http") ||
            url?.startsWith("//") ||
            url?.startsWith("whatsapp") ||
            url?.startsWith("mailto") ||
            url?.startsWith("tel")
        ) {
            isExternal = true
        } else if (isInternal === false) {
            isExternal = true
        }
        if (!isExternal) {
            const InternalButton = PlainButton.withComponent(Link)
            return (
                <InternalButton to={url} {...linkButtonProps}>
                    {buttonContent}
                </InternalButton>
            )
        } else {
            const LinkButton = PlainButton.withComponent("a")
            if (download) {
                return (
                    <LinkButton
                        download
                        target="_blank"
                        rel="noreferrer"
                        href={url}
                        {...linkButtonProps}
                    >
                        {buttonContent}
                    </LinkButton>
                )
            } else {
                return (
                    <LinkButton
                        href={url}
                        target="_blank"
                        rel="noreferrer"
                        {...linkButtonProps}
                    >
                        {buttonContent}
                    </LinkButton>
                )
            }
        }
    }

    if (icon) {
        return <IconButton {...buttonProps}>{icon}</IconButton>
    }

    return <PlainButton {...buttonProps}>{buttonContent}</PlainButton>
}

export default Button
